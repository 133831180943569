import { render } from "@itsjavi/jsx-runtime"

const Player = ({ player }) => (
	<span className="player">
		<img className="img-inline" src={`https://www.mc-heads.net/avatar/${player}/32.png`} />
		{player}
	</span>
)

type Screenshot = {
	file: string
	season: number
	location?: string
	by: string
	active?: boolean
}

const CarouselItem = ({ file, season, location, by, active }: Screenshot): JSX.Element => (
	<div className={["carousel-item", active ? "active" : undefined]}>
		<img {...{[active ? "src" : "data-src"]: "./screenshots/" + file}} />
		<div className="carousel-caption">
			Season {season}<br />
			{location != undefined ? <>{location}<br /></> : <></>}
			<Player player={by} />
		</div>
	</div>
)

const loadSlide = (slide: HTMLElement) => {
	const img = slide.querySelector("img[data-src]")
	if (img == undefined) return
	img.setAttribute("src", img.getAttribute("data-src"))
	img.removeAttribute("data-src")
}

export const Carousel = ({ screenshots }: { screenshots: Screenshot[] }) =>
	<div className="carousel-inner">{screenshots.map(CarouselItem)}</div>

export default (elem: HTMLElement, screenshots: Screenshot[]) => {
	render(<Carousel screenshots={screenshots} />, elem)
	elem.addEventListener("slide.bs.carousel", e => loadSlide((e as any).relatedTarget))
}
